<template>
  <div style="height: 460px;width: 400px;text-align: center;margin-left: 80px">
    <div class="dialog-title">{{ buttonText }}控制器</div>
    <el-form :label-position="labelPosition" label-width="120px">
      <el-form-item label="控制器名称">
        <el-input v-model="dataForm.name"></el-input>
      </el-form-item>
      <el-form-item label="控制器序列号">
        <el-input v-model="dataForm.num "></el-input>
      </el-form-item>
      <el-form-item label="控制器GPRSID">
        <el-input v-model="dataForm.gprsid "></el-input>
      </el-form-item>
      <el-form-item label="所属大棚">
        <el-select v-model="dataForm.gId" placeholder="请选择" style="width: 280px;" >
          <el-option
              v-for="item in greenHouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="dataForm.status" placeholder="请选择" style="width: 280px;">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-col>
      <el-button class="dialog-button" @click="update">{{ buttonText }}</el-button>
    </el-col>
  </div>
</template>
<script>
export default {
  inject: [
    'reload'
  ],
  data() {
    return {
      dataForm: {
        "bId": JSON.parse(localStorage.getItem('user_nongye')).bId,
        "gId": '',
        "gprsid": '',
        "name": '',
        "num": '',
        "status": ''
      },
      labelPosition: 'right',
      buttonText: '添加',
      name: '',
      product: '',
      options: [],
      statusList: [
        {
          name: '在线',
          id: 1
        }, {
          name: '离线',
          id: 0
        }
      ],
      id: '',

    }
  },
  props: {
    greenHouseList: {
      type: Array,
    },
    changeList: {
      type: Object,
    }
  },
  mounted() {
    if (this.changeList.name) {
      this.dataForm.gId = this.changeList.gId
      this.dataForm.gprsid = this.changeList.gprsid
      this.dataForm.name = this.changeList.name
      this.dataForm.num = this.changeList.num
      this.dataForm.status = this.changeList.status
      this.id = this.changeList.id
      this.buttonText = '修改'
    }

  },
  methods: {
    clearData() {
      this.dataForm ={
        "bId": JSON.parse(localStorage.getItem('user_nongye')).bId,
        "gId": '',
        "gprsid": '',
        "name": '',
        "num": '',
        "status": ''
      }
    },
    update() {
      if (this.id !== '') {  //修改
        this.dataForm['id'] = this.id
         this.$api.post('faci/update', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            this.reload()
          }
        })
      } else {   //添加
        this.$api.post('faci/save', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('添加成功')
            this.reload()
          }
        })
      }

    }
  },
  watch: {
    changeList: {
      handler: function (val) {
        if (this.changeList.name) {
          this.dataForm.gId = val.gId
          this.dataForm.gprsid = val.gprsid
          this.dataForm.name = val.name
          this.dataForm.num = val.num
          this.dataForm.status = val.status
          this.id = val.id
          this.buttonText = '修改'
        } else {
          this.dataForm.gId = ''
          this.dataForm.gprsid = ''
          this.dataForm.name = ''
          this.dataForm.num = ''
          this.dataForm.status = ''
          this.buttonText = '添加'
        }
      }
    }
  }
}
</script>

<style scoped>
.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
